const colors = {
  primary: '#2689BE', // light sea green
  secondary: '#D55457', // indian red
  light: '#E4EFF4', // light steel blue
  medium: '#85a0b6', // lightened version of dark slate gray
  dark: '#A9B7C0', // blue tint
  black: '#222220', // black
  white: '#FFFFFF', // white
  navBar: '#A9B7C0', // grey blue
  footer: '#CCCBC6', // powder
};

export {colors};