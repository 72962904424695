import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";
import { lighten, darken } from "polished";
import { colors } from "../styles/theme";
import LogoSVG from '../images/LogoSVG';
import ShareSVG from '../images/ShareSVG';

const Wrapper = styled.div`
  z-index: 2;
  background-color: ${colors.navBar};
`;

const Nav = styled.nav`
  display: flex;
  max-width: 960px;
  margin: auto;
  height: 65px;
  justify-content: space-between;
  border-top: 1px solid ${darken(0.1, colors.navBar)};
  border-bottom: 1px solid ${darken(0.1, colors.navBar)};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const Title = styled(Link)`
  font-weight: 700;
  text-decoration: none;
  color: #ffffff;
  font-size: 18pt;
  @media (max-width: 500px) {
    font-size: 14pt;
  }
`;

const Right = styled.div`
  display: flex;
  font-size: 12pt;
  @media (max-width: 500px) {
    font-size: 10pt;
  }
  a {
    color: ${colors.white}
    text-decoration: none;
  }
`;

const LogoLink = styled(Link)`
  svg {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    transition: all 0.4s ease;
    fill: ${colors.light};
    &:hover {
      transform: rotate(72deg);
    }
    #a2 {
      fill: ${colors.secondary};
    }
    #a3 {
      fill: ${colors.primary};
    }
  }
`;

const NavMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  &:after {
    content: "\u2192";
    color: ${colors.white};
    display: inline-block;
    margin-left: 5px;
    transition: all 0.1s ease;
  }
  transition: all 0.1s ease;
  @media (hover: none) {
    background: ${props =>
      props.toggle ? colors.navBar : lighten(0.05, colors.navBar)};
    &:after {
      transform: ${props => (props.toggle ? "rotate(0deg)" : "rotate(90deg)")};
    }
  }
  @media (hover: hover) {
    &:hover {
      background: ${lighten(0.05, colors.navBar)};
    }
    &:hover:after {
      transform: rotate(90deg);
    }
  }
`;

const NavMenu = styled.div`
  position: absolute;
  flex-direction: column;
  z-index: -1;
  left: 0;
  top: 65px;
  width: 100%;
  background: ${colors.navBar};
  border-radius: 0 0 5px 5px;
  @media (hover: none) {
    display: ${props => (props.toggle ? "none" : "flex")};
    visibility: ${props => (props.toggle ? "hidden" : "visible")};
    opacity: ${props => (props.toggle ? 0 : 1)};
  }
  @media (hover: hover) {
    display: none;
    visibility: hidden;
    opacity: 0;
    ${NavMenuItem}:hover & {
      display: flex;
      visibility: visible;
      opacity: 1;
    }
  }
`;

const SLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 35px;
  padding-left: 10px;
  border-top: 1px solid ${lighten(0.05, colors.navBar)};
  font-size: 10pt;
  @media (max-width: 500px) {
    font-size: 8pt;
  }
  &:hover {
    background: ${lighten(0.05, colors.navBar)};
  }
  &:last-of-type {
    border-radius: 0 0 5px 5px;
  }
`;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutToggle: true,
      companyToggle: true
    };
    
    this.aboutHandler = this.aboutHandler.bind(this);
    this.companyHandler = this.companyHandler.bind(this);
  }
  
  aboutHandler() {
    this.setState(prevState => ({
      aboutToggle: !prevState.aboutToggle,
      companyToggle: true
    }));
  }

  companyHandler() {
    this.setState(prevState => ({
      aboutToggle: true,
      companyToggle: !prevState.companyToggle
    }));
  }

  render() {
    return (
      <Wrapper>
        <Nav>
          <Left>
            <LogoLink to="/">
              <LogoSVG />
            </LogoLink>
            <Title to="/">BEM Consulting LLC.</Title>
          </Left>
          <Right>
            <NavMenuItem
              onClick={this.aboutHandler}
              toggle={this.state.aboutToggle}
            >
              <a href="#">About</a>
              <NavMenu toggle={this.state.aboutToggle}>
                <SLink to="/services">Services</SLink>
                <SLink to="/process">Process</SLink>
                <SLink to="/industries">Industries</SLink>
              </NavMenu>
            </NavMenuItem>
            <NavMenuItem
              onClick={this.companyHandler}
              toggle={this.state.companyToggle}
            >
              <a href="#">Company</a>
              <NavMenu toggle={this.state.companyToggle}>
                <SLink to="/team">Team</SLink>
                <SLink to="/careers">Careers</SLink>
                <SLink to="/contact">Contact</SLink>
              </NavMenu>
            </NavMenuItem>
          </Right>
        </Nav>
      </Wrapper>
    );
  }
}

export default Navigation;