import React from "react";
import Helmet from "react-helmet";
import Headroom from "react-headroom";
import { graphql } from 'gatsby'
import Typography from 'typography'
import grandViewTheme from 'typography-theme-grand-view'
import Normalize from 'react-normalize'
import { TypographyStyle } from 'react-typography'

//import "../styles/normalize.css";
//import "../styles/base.css";

import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import SEO from '../components/seo'
import { ReactComponent as favicon } from '../images/logo.svg';

grandViewTheme.overrideThemeStyles = ({ adjustFontSizeTo, scale, rhythm }, options) => ({
  'a:hover,a:active': {
    color: 'none',
  }
});
const typography = new Typography(grandViewTheme)

export default ({ location, children }) => {
  let bottom;
  if (location && (location.pathname === "/contact" || location.pathname === "/thanks")) {
    bottom = null;
  } else if (location && location.pathname === "/careers") {
    bottom = (
      <div>
        <Footer />
      </div>
    );
  } else {
    bottom = (
      <div>
        <Footer />
      </div>
    );
  }
  return (
    <div>
      <TypographyStyle typography={typography} />
      <Normalize />
      <SEO title="BEM Consulting LLC." keywords={[`bem`, `consulting`, `bem consulting`, `bem consulting llc`, `networking`]} />
      <Headroom>
        <Navigation />
      </Headroom>
      <div>{children}</div>
      {bottom}
      {/* Hubspot code injection */}
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/4706986.js"
      />
    </div>
  );
};

export const pageQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;